import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import BlogSinglePost from "../components/Blog/BlogSinglePost";

const Post = ({ data }) => {
  return (
    <Layout
      headerStyle="standard"
      headerLinkColor="dark"
      headerHasBorder={false}
    >
      <SearchEngineOptimization
        title="Light Resin Transfer Molding: What Is It | Painted Rhino"
        description="Light resin transfer molding has several advantages over traditional manufacturing processes. Learn more about light resin transfer molding is and its benefits!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <BlogSinglePost
        title="Light Resin Transfer Molding: What Is It and Why You Should Consider Using It"
        date="September 22, 2022"
        author="Painted Rhino"
        category=""
        featuredImage={data.featuredImage.childImageSharp.fluid}
        content={
          <>
            <p>
              Light resin transfer molding (LRTM) is a manufacturing process
              that combines the advantages of injection molding and resin
              infusion to produce composite parts with complex geometries. LRTM
              is well suited for the production of small to medium-sized parts
              with intricate shapes and can be used to manufacture parts with
              both internally and externally oriented features. LRTM has several
              advantages over traditional manufacturing processes. We will
              discuss these benefits and more below, so keep reading to find out
              if light resin transfer molding is the right solution for your
              next composite project!
            </p>
            <h2>Understanding Light Resin Transfer Molding</h2>
            <p>
              {" "}
              So, what is{" "}
              <a href="https://www.paintedrhino.com/light-resin-transfer-molding/">
                light resin transfer molding? 
              </a> Why should you use it? This section details all you need to know
              about this exciting manufacturing process. In LRTM, a preform
              (usually in the form of a 3D-printed model or CNC machined mold)
              is placed into a mold. The preform can be made from any material
              that can withstand the temperatures and pressures of the infusion
              process; common materials include aluminum and stainless steel. A
              vacuum is then used to draw the resin into the preform, filling
              all of the voids and ensuring there are no entrapped air bubbles.
              After the vacuum is released, the excess resin is removed from the
              part’s surface and allowed to cure.
            </p>
            <h2>The Benefits of Light Resin Transfer Molding</h2>
            <p>
              There are many unique benefits to using light resin transfer
              molding, including:
            </p>
            <ul>
              <li>
                <p>
                  <b>Tight Tolerances: </b>LRTM can be used to produce
                  components with extremely tight dimensional tolerances, making
                  it ideal for applications where precision is critical.
                  Numerous injection and resin infusion points also help to
                  ensure that the resin is evenly distributed throughout the
                  preform.
                </p>
              </li>
              <li>
                <p>
                  <b>Improved Strength and Stiffness: </b>
                  The pressure used in LRTM helps to compress the fibers in the
                  preform, resulting in a final part that is stronger and
                  stiffer than one produced using traditional infusion methods.
                </p>
              </li>
              <li>
                <p>
                  <b>Reduced Porosity: </b>
                  LRTM reduces the number of voids in the final composite part,
                  resulting in a stronger, lighter-weight part.
                </p>
              </li>
              <li>
                <p>
                  <b>Reduction in Labor Costs: </b>
                  LRTM is a largely automated process. As such, it reduces the
                  amount of labor required in manufacturing. Additionally, LRTM
                  can be used to produce components with complex geometries that
                  would be difficult or impossible to produce using traditional
                  methods.
                </p>
              </li>
              <li>
                <p>
                  <b>Increased Productivity: </b>
                  LRTM is a fast and efficient manufacturing process, with cycle
                  times typically shorter than traditional methods. This
                  increased efficiency results in reduced overall production
                  costs.
                </p>
              </li>
              <li>
                <p>
                  <b>Increased Design Freedom: </b>
                  Light resin transfer molding allows for greater design freedom
                  than other composite manufacturing processes. This is because
                  the 3D-printed preform can be made to any shape or size; the
                  only limitation is the mold size.
                </p>
              </li>
              <li>
                <p>
                  <b>Improved Surface Finish: </b>
                  Light resin transfer molding produces parts with a smooth
                  surface finish, as the preform acts as a template for the
                  final part. This is in contrast to other composite
                  manufacturing processes, such as hand layup, where the
                  operator's skill determines the surface finish. Light resin
                  transfer molding is an advanced composite manufacturing
                  process that offers numerous advantages over traditional
                  methods. Light resin transfer molding is an advanced composite
                  manufacturing process that offers numerous advantages over
                  traditional methods.
                </p>
              </li>
            </ul>
            <h2>Do You Need Professional Light Resin Transfer Molding?</h2>
            <p>
              If you are looking for a composite manufacturing partner that can
              provide light resin transfer molding services, <a data-modal-open="modal-contact">
                contact the experts at Painted Rhino today</a>. We are a leading provider of composite manufacturing services, and we would be happy to discuss your project with you. Request a quote today!

            </p>
          </>
        }
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/FB_Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Twitter_Global.jpg" }
    ) {
      publicURL
    }
    featuredImage: file(
      relativePath: {
        eq: "blog/painted-rhino-light-resin-transfer-molding-what-is-it-and-why-you-should-consider-using-it.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default Post;
